var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-Roboto"
  }, [_c('Parent'), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "d-flex pt-4 pl-4"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeDay('prev');
      }
    }
  }, [_c('v-icon', [_vm._v("$purpleArrowLeft")])], 1), _c('div', {
    staticClass: "font-18px mx-5 primary--text"
  }, [_vm._v(" " + _vm._s(_vm.dateBackendFormatted) + " ")]), _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeDay('next');
      }
    }
  }, [_c('v-icon', [_vm._v("$purpleArrowRight")])], 1)], 1), _c('div', {
    staticClass: "primary--text d-flex ml-4 font-16px"
  }, [_vm._l(_vm.dailyReportWeather, function (weather, i) {
    return [_c('div', {
      key: "daily-report-weather-".concat(i),
      staticClass: "d-flex align-center"
    }, [_c('v-icon', {
      staticClass: "mr-1 ml-2"
    }, [_vm._v(_vm._s(_vm.weathers[weather - 1].icon))]), _c('div', {
      staticClass: "mr-1"
    }, [_vm._v(_vm._s(_vm.weathers[weather - 1].text))]), i !== _vm.dailyReportWeather.length - 1 ? [_vm._v(" / ")] : _vm._e()], 2)];
  })], 2)]), _c('div', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "daily-report-background"
  }, [_c('div', {
    staticClass: "daily-report-table"
  }, [_c('v-simple-table', {
    staticClass: "table-text"
  }, [_c('tbody', [_c('tr', {
    staticClass: "text-center"
  }, _vm._l(_vm.dailyReportNameList, function (item) {
    return _c('td', {
      key: item,
      staticClass: "font-12px table-column"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)])])], 1)]), _c('v-simple-table', {
    staticClass: "daily-report-result-table pb-2"
  }, [_c('tbody', [_c('tr', {
    staticClass: "text-center"
  }, [_c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.dailyReportSummary.sales || 0)) + " ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.dailyReportSummary.cashSales || 0)) + " ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.dailyReportSummary.costs || 0)) + " ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.dailyReportSummary.profitOrLoss || 0)) + " ")])])])])], 1)]), _c('v-card', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex pt-5 ml-4 pb-5 align-center"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center px-4",
    attrs: {
      "color": "#525252",
      "depressed": "",
      "flat": "",
      "height": "29px"
    }
  }, [_c('div', {
    staticClass: "font-16px white--text "
  }, [_vm._v(" " + _vm._s("".concat(_vm.dateFrontendFormatted, "\u65E5\u5831")) + " ")])]), _c('div', {
    staticClass: "ml-3"
  }, [_c('v-btn-toggle', {
    staticClass: "d-flex toggle-button",
    attrs: {
      "borderless": "",
      "dense": ""
    },
    model: {
      value: _vm.selectedDailyReportToggle,
      callback: function callback($$v) {
        _vm.selectedDailyReportToggle = $$v;
      },
      expression: "selectedDailyReportToggle"
    }
  }, _vm._l(_vm.reportToggleButtonNames, function (item, index) {
    return _c('v-btn', {
      key: "report-toggle-button-".concat(index),
      staticClass: "text-a1 selected-btn mx-3",
      attrs: {
        "height": "26px",
        "color": "transparent",
        "rounded": "",
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]);
  }), 1)], 1)], 1)]), _c('div', {
    staticClass: "mt-6 mr-7 d-flex"
  }, [_c('div', {
    staticClass: "mr-8 mt-1 font-14px text-light-grey"
  }, [_vm._v(" 最終編集者： ")]), _c('div', [_vm.showDailyReport ? _c('v-icon', {
    attrs: {
      "size": "12"
    },
    on: {
      "click": function click($event) {
        _vm.showDailyReport = !_vm.showDailyReport;
      }
    }
  }, [_vm._v("$arrowUp")]) : _vm._e(), !_vm.showDailyReport ? _c('v-icon', {
    attrs: {
      "size": "12"
    },
    on: {
      "click": function click($event) {
        _vm.showDailyReport = !_vm.showDailyReport;
      }
    }
  }, [_vm._v("$arrowDown")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "gray-divider mt-0"
  }), _c('div', {}, [_vm.showDailyReport ? [_c('FullDailyReport', {
    attrs: {
      "selected-category": _vm.selectedDailyReportToggle
    }
  })] : _vm._e()], 2)]), _c('v-card', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex pt-5 ml-4 pb-5 align-center"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center px-4",
    attrs: {
      "color": "#525252",
      "depressed": "",
      "flat": "",
      "height": "29px"
    }
  }, [_c('div', {
    staticClass: "font-16px white--text "
  }, [_vm._v(" " + _vm._s(_vm.cumulativeDate) + " ")])]), _c('div', {
    staticClass: "ml-3"
  }, [_c('v-btn-toggle', {
    staticClass: "d-flex toggle-button",
    attrs: {
      "borderless": "",
      "dense": ""
    },
    model: {
      value: _vm.selectedCumulativeReportToggle,
      callback: function callback($$v) {
        _vm.selectedCumulativeReportToggle = $$v;
      },
      expression: "selectedCumulativeReportToggle"
    }
  }, _vm._l(_vm.reportToggleButtonNames, function (item, index) {
    return _c('v-btn', {
      key: "report-toggle-cumulative-button-".concat(index),
      staticClass: "text-a1 selected-btn mx-3",
      attrs: {
        "height": "26px",
        "color": "transparent",
        "rounded": "",
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]);
  }), 1)], 1)], 1)])]), _c('div', {
    staticClass: "gray-divider mt-0"
  }), _c('div', {}, [_c('CumulativeDailyReport', {
    attrs: {
      "selected-category": _vm.selectedCumulativeReportToggle
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }