<template>
  <v-card>
    <template v-if="selectedCategory !== 'cost'">
      <div class="mt-5">
        <div class="daily-report-background">
          <div class="daily-report-table">
            <v-simple-table class="table-text">
              <tbody>
                <tr class="text-center">
                  <td
                    v-for="item in fullDayReportTableNames"
                    :key="`daily-report-detail-${item}`"
                    class="font-12px table-column"
                  >
                    {{ item }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
        <v-simple-table class="daily-report-result-table pb-2">
          <tbody>
            <tr class="text-center">
              <td class="result-column">
                {{ dailyReport?.number_of_total_customer || 0 }}日
              </td>
              <td class="result-column">
                {{ dailyReport?.number_of_customer_store || 0 }}人
              </td>
              <td class="result-column">
                {{ dailyReport?.number_of_total_group || 0 }}組
              </td>
              <td class="result-column">
                {{ dailyReport?.number_of_group_store || 0 }}組
              </td>
              <td class="result-column">
                {{ dailyReport?.average_customer_spend || 0 | formatMoney }}
              </td>
              <td class="result-column">
                {{
                  dailyReport?.average_in_shop_customer_spend || 0 | formatMoney
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
      <div class="daily-report-card mt-4 ml-4 d-flex align-center font-14px">
        <div class="d-flex gray-text ml-3 total_sales">
          <div class="gray-text">売上</div>
          <div class="ml-16 pl-3">
            {{ dailyReport?.sales || 0 | formatMoney }}
          </div>
        </div>
        <div class="d-flex">
          <div class="light-gray-text">( 現金売上 )</div>
          <div class="ml-5 light-gray-text">
            {{ dailyReport?.cash_sales || 0 | formatMoney }}
          </div>
        </div>
      </div>
      <div class="mt-5 pb-7 ml-4 d-flex align-center font-14px gray-text">
        <div class="sale-summary">
          <div class="d-flex">
            <div class="ml-3">フード</div>
            <div class="ml-15">
              {{ dailyReport?.food_sales || 0 | formatMoney }}
            </div>
          </div>
          <div class="d-flex mt-6">
            <div class="ml-3">テイクアウト</div>
            <div class="ml-5">
              {{ dailyReport?.takeout_unit_sales || 0 | formatMoney }}
            </div>
          </div>
        </div>
        <div class="sale-summary">
          <div class="d-flex">
            <div>ドリンク</div>
            <div class="ml-12">
              {{ dailyReport?.drink_sales || 0 | formatMoney }}
            </div>
          </div>
          <div class="d-flex mt-6">
            <div>その他</div>
            <div class="ml-15">
              {{ dailyReport?.others_sales || 0 | formatMoney }}
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex mt-n8">
            <div>デリバリー</div>
            <div class="ml-8">
              {{ dailyReport?.delivery_sales || 0 | formatMoney }}
            </div>
          </div>
        </div>
      </div>
      <div class="pb-8" v-if="dailyReport?.notes">
        <div class="message-card ml-4 mr-8">
          <div class="font-14px my-4 ml-4 text-light-grey mx-683px">
            {{ dailyReport.notes }}
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="daily-report-card mt-5 ml-4 d-flex align-center font-14px">
        <div class="d-flex gray-text ml-3 total_sales">
          <div class="gray-text">総コスト</div>
          <div class="ml-14">
            {{ costsReport?.costs || 0 | formatMoney }}
          </div>
        </div>
      </div>
      <div class="mt-5 pb-7 ml-4 d-flex align-center font-14px gray-text">
        <div class="sale-summary">
          <div class="d-flex">
            <div class="ml-3">人件費(L)</div>
            <div class="ml-12">
              {{ costsReport?.labor_cost || 0 | formatMoney }} ({{
                costsReport?.labor_cost_percentage
              }}%)
            </div>
          </div>
          <div class="d-flex mt-6">
            <div class="ml-3">PF手数料(P)</div>
            <div class="ml-7">
              {{ costsReport?.platform_cost || 0 | formatMoney }} ({{
                costsReport?.platform_cost_percentage
              }}%)
            </div>
          </div>
        </div>
        <div class="sale-summary">
          <div class="d-flex">
            <div>食材費(F)</div>
            <div class="ml-12">
              {{ costsReport?.foodstuff_cost || 0 | formatMoney }} ({{
                costsReport?.food_cost_percentage
              }}%)
            </div>
          </div>
          <div class="d-flex mt-6">
            <div>その他(O)</div>
            <div class="ml-12">
              {{ costsReport?.others_cost || 0 | formatMoney }} ({{
                costsReport?.others_cost_percentage
              }}%)
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex mt-n8">
            <div>家賃(R)</div>
            <div class="ml-14">
              {{ costsReport?.rent_cost || 0 | formatMoney }} ({{
                costsReport?.rent_cost_percentage
              }}%)
            </div>
          </div>
        </div>
      </div>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FullDailyReport",
  props: {
    selectedCategory: {
      type: String,
      default: "all",
      required: true
    }
  },
  filters: {
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      if (value === -1) return "￥--";
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters(["getShopDailyReport"]),
    dailyReport() {
      if (this.selectedCategory === "all")
        return this.getShopDailyReport.daily_sales;
      else if (this.selectedCategory === "lunch")
        return this.getShopDailyReport.lunch;
      else return this.getShopDailyReport.dinner;
    },
    ...mapGetters(["getShopDailyReport"]),
    costsReport() {
      return this.getShopDailyReport.daily_costs;
    }
  },
  data() {
    return {
      fullDayReportTableNames: [
        "総客数",
        "店内利用客数",
        "総組数",
        "店内利用組数",
        "客単価",
        "店内利用客単価"
      ]
    };
  }
};
</script>

<style lang="scss" src="./index.scss" scoped></style>
