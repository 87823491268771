<template>
  <div class="font-Roboto">
    <Parent />
    <v-card class="mt-3">
      <div class="d-flex pt-4 pl-4">
        <div class="d-flex">
          <v-btn icon small @click="changeDay('prev')"
            ><v-icon>$purpleArrowLeft</v-icon></v-btn
          >
          <div class="font-18px mx-5 primary--text">
            {{ dateBackendFormatted }}
          </div>
          <v-btn icon small @click="changeDay('next')"
            ><v-icon>$purpleArrowRight</v-icon></v-btn
          >
        </div>
        <div class="primary--text d-flex ml-4 font-16px">
          <template v-for="(weather, i) in dailyReportWeather">
            <div :key="`daily-report-weather-${i}`" class="d-flex align-center">
              <v-icon class="mr-1 ml-2">{{
                weathers[weather - 1].icon
              }}</v-icon>
              <div class="mr-1">{{ weathers[weather - 1].text }}</div>
              <template v-if="i !== dailyReportWeather.length - 1">
                /
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="mt-5">
        <div class="daily-report-background">
          <div class="daily-report-table">
            <v-simple-table class="table-text">
              <tbody>
                <tr class="text-center">
                  <td
                    v-for="item in dailyReportNameList"
                    :key="item"
                    class="font-12px table-column"
                  >
                    {{ item }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
        <v-simple-table class="daily-report-result-table pb-2">
          <tbody>
            <tr class="text-center">
              <td class="result-column">
                {{ dailyReportSummary.sales || 0 | formatMoney }}
              </td>
              <td class="result-column">
                {{ dailyReportSummary.cashSales || 0 | formatMoney }}
              </td>
              <td class="result-column">
                {{ dailyReportSummary.costs || 0 | formatMoney }}
              </td>
              <td class="result-column">
                {{ dailyReportSummary.profitOrLoss || 0 | formatMoney }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card>
    <v-card class="mt-5">
      <div class="d-flex justify-space-between">
        <div class="d-flex pt-5 ml-4 pb-5 align-center">
          <div class="d-flex">
            <v-card
              color="#525252"
              class="d-flex align-center justify-center px-4"
              depressed
              flat
              height="29px"
            >
              <div class="font-16px white--text ">
                {{ `${dateFrontendFormatted}日報` }}
              </div>
            </v-card>
            <div class="ml-3">
              <v-btn-toggle
                v-model="selectedDailyReportToggle"
                borderless
                dense
                class="d-flex toggle-button"
              >
                <v-btn
                  v-for="(item, index) in reportToggleButtonNames"
                  :key="`report-toggle-button-${index}`"
                  height="26px"
                  class="text-a1 selected-btn mx-3"
                  color="transparent"
                  rounded
                  :value="item.value"
                >
                  {{ item.text }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </div>
        <div class="mt-6 mr-7 d-flex">
          <div class="mr-8 mt-1 font-14px text-light-grey">
            <!-- TODO: Add missing last updated by -->
            最終編集者：
          </div>
          <div>
            <v-icon
              v-if="showDailyReport"
              @click="showDailyReport = !showDailyReport"
              size="12"
              >$arrowUp</v-icon
            >
            <v-icon
              v-if="!showDailyReport"
              @click="showDailyReport = !showDailyReport"
              size="12"
              >$arrowDown</v-icon
            >
          </div>
        </div>
      </div>
      <div class="gray-divider mt-0"></div>
      <div class="">
        <template v-if="showDailyReport">
          <FullDailyReport :selected-category="selectedDailyReportToggle" />
        </template>
      </div>
    </v-card>
    <v-card class="mt-5">
      <div class="d-flex justify-space-between">
        <div class="d-flex pt-5 ml-4 pb-5 align-center">
          <div class="d-flex">
            <v-card
              color="#525252"
              class="d-flex align-center justify-center px-4"
              depressed
              flat
              height="29px"
            >
              <div class="font-16px white--text ">
                {{ cumulativeDate }}
              </div>
            </v-card>
            <div class="ml-3">
              <v-btn-toggle
                v-model="selectedCumulativeReportToggle"
                borderless
                dense
                class="d-flex toggle-button"
              >
                <v-btn
                  v-for="(item, index) in reportToggleButtonNames"
                  :key="`report-toggle-cumulative-button-${index}`"
                  height="26px"
                  class="text-a1 selected-btn mx-3"
                  color="transparent"
                  rounded
                  :value="item.value"
                >
                  {{ item.text }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </div>
      </div>
      <div class="gray-divider mt-0"></div>
      <div class="">
        <CumulativeDailyReport
          :selected-category="selectedCumulativeReportToggle"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import Parent from "@/views/admin/Store/Index";
import CumulativeDailyReport from "@/components/admin/store/daily-report/CumulativeDailyReport.vue";
import FullDailyReport from "@/components/admin/store/daily-report/FullDailyReport.vue";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "Detail",
  components: {
    Parent,
    FullDailyReport,
    CumulativeDailyReport
  },
  filters: {
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      if (value === -1) return "￥--";
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters(["getShopDailyReport"]),
    reportDate() {
      return this.$route.params.date;
    },
    dateBackendFormatted() {
      return this.currentDate?.format("YYYY-MM-DD");
    },
    dateFrontendFormatted() {
      return this.currentDate?.format("YYYY/MM/DD");
    },
    beginningOfMonthFormatted() {
      return this.currentDate.startOf("month").format("YYYY/MM/DD");
    },
    cumulativeDate() {
      return `${this.beginningOfMonthFormatted} ～ ${this.dateFrontendFormatted}累計`;
    },
    dailySales() {
      return this.getShopDailyReport.daily_sales;
    },
    dailyCosts() {
      return this.getShopDailyReport.daily_costs;
    },
    dailyReportSummary() {
      return {
        sales: this.dailySales?.sales || 0,
        costs: this.dailySales?.costs || 0,
        cashSales: this.dailySales?.cash_sales || 0,
        profitOrLoss: this.dailySales?.profit_or_loss || 0
      };
    },
    dailyReportWeather() {
      return this.dailySales?.weather || [];
    }
  },
  data() {
    return {
      currentDate: dayjs(),
      weathers: [
        { icon: "$purpleSunny", text: "晴れ" },
        { icon: "$purpleCloudy", text: "曇り" },
        { icon: "$purpleRainyWeather", text: "雨" }
      ],
      selectedDailyReportToggle: "all",
      selectedCumulativeReportToggle: "all",
      showDailyReport: true,
      showCumulativeReport: true,
      dailyReportNameList: ["売上", "( 現金売上 )", "コスト", "損益"],
      reportToggleButtonNames: [
        { value: "all", text: "当日売上" },
        { value: "lunch", text: "ランチ売上" },
        { value: "dinner", text: "ディナー売上" },
        { value: "cost", text: "当日コスト" }
      ]
    };
  },
  mounted() {
    if (!this.reportDate) {
      this.reloadWithChangedDate(this.currentDate.format("YYYY-MM-DD"));
    } else {
      this.currentDate = dayjs(this.reportDate);
    }
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      await this.$store.dispatch("SHOP_DAILY_REPORT", {
        shop_id: this.$route.params.shop_id,
        date: this.dateBackendFormatted
      });
    },
    reloadWithChangedDate(date) {
      this.$router.replace({
        name: "DailyReportDetail",
        params: {
          shop_id: this.$route.params.shop_id,
          date: date
        }
      });
    },
    changeDay(direction) {
      if (direction === "next")
        this.currentDate = this.currentDate.add(1, "day");
      else this.currentDate = this.currentDate.subtract(1, "day");

      this.reloadWithChangedDate(this.dateBackendFormatted);
      this.getDataFromApi();
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
