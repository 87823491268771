var render = function () {
  var _vm$dailyReport, _vm$dailyReport2, _vm$dailyReport3, _vm$dailyReport4, _vm$dailyReport5, _vm$dailyReport6, _vm$dailyReport7, _vm$dailyReport8, _vm$dailyReport9, _vm$dailyReport10, _vm$dailyReport11, _vm$dailyReport12, _vm$dailyReport13, _vm$dailyReport14, _vm$costsReport, _vm$costsReport2, _vm$costsReport3, _vm$costsReport4, _vm$costsReport5, _vm$costsReport6, _vm$costsReport7, _vm$costsReport8, _vm$costsReport9, _vm$costsReport10, _vm$costsReport11;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_vm.selectedCategory !== 'cost' ? [_c('div', {
    staticClass: "mt-5"
  }, [_c('div', {
    staticClass: "daily-report-background"
  }, [_c('div', {
    staticClass: "daily-report-table"
  }, [_c('v-simple-table', {
    staticClass: "table-text"
  }, [_c('tbody', [_c('tr', {
    staticClass: "text-center"
  }, _vm._l(_vm.fullDayReportTableNames, function (item) {
    return _c('td', {
      key: "daily-report-detail-".concat(item),
      staticClass: "font-12px table-column"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0)])])], 1)]), _c('v-simple-table', {
    staticClass: "daily-report-result-table pb-2"
  }, [_c('tbody', [_c('tr', {
    staticClass: "text-center"
  }, [_c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(((_vm$dailyReport = _vm.dailyReport) === null || _vm$dailyReport === void 0 ? void 0 : _vm$dailyReport.number_of_total_customer) || 0) + "日 ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(((_vm$dailyReport2 = _vm.dailyReport) === null || _vm$dailyReport2 === void 0 ? void 0 : _vm$dailyReport2.number_of_customer_store) || 0) + "人 ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(((_vm$dailyReport3 = _vm.dailyReport) === null || _vm$dailyReport3 === void 0 ? void 0 : _vm$dailyReport3.number_of_total_group) || 0) + "組 ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(((_vm$dailyReport4 = _vm.dailyReport) === null || _vm$dailyReport4 === void 0 ? void 0 : _vm$dailyReport4.number_of_group_store) || 0) + "組 ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$dailyReport5 = _vm.dailyReport) === null || _vm$dailyReport5 === void 0 ? void 0 : _vm$dailyReport5.average_customer_spend) || 0)) + " ")]), _c('td', {
    staticClass: "result-column"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$dailyReport6 = _vm.dailyReport) === null || _vm$dailyReport6 === void 0 ? void 0 : _vm$dailyReport6.average_in_shop_customer_spend) || 0)) + " ")])])])])], 1), _c('div', {
    staticClass: "daily-report-card mt-4 ml-4 d-flex align-center font-14px"
  }, [_c('div', {
    staticClass: "d-flex gray-text ml-3 total_sales"
  }, [_c('div', {
    staticClass: "gray-text"
  }, [_vm._v("売上")]), _c('div', {
    staticClass: "ml-16 pl-3"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$dailyReport7 = _vm.dailyReport) === null || _vm$dailyReport7 === void 0 ? void 0 : _vm$dailyReport7.sales) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "light-gray-text"
  }, [_vm._v("( 現金売上 )")]), _c('div', {
    staticClass: "ml-5 light-gray-text"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$dailyReport8 = _vm.dailyReport) === null || _vm$dailyReport8 === void 0 ? void 0 : _vm$dailyReport8.cash_sales) || 0)) + " ")])])]), _c('div', {
    staticClass: "mt-5 pb-7 ml-4 d-flex align-center font-14px gray-text"
  }, [_c('div', {
    staticClass: "sale-summary"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("フード")]), _c('div', {
    staticClass: "ml-15"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$dailyReport9 = _vm.dailyReport) === null || _vm$dailyReport9 === void 0 ? void 0 : _vm$dailyReport9.food_sales) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex mt-6"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("テイクアウト")]), _c('div', {
    staticClass: "ml-5"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$dailyReport10 = _vm.dailyReport) === null || _vm$dailyReport10 === void 0 ? void 0 : _vm$dailyReport10.takeout_unit_sales) || 0)) + " ")])])]), _c('div', {
    staticClass: "sale-summary"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', [_vm._v("ドリンク")]), _c('div', {
    staticClass: "ml-12"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$dailyReport11 = _vm.dailyReport) === null || _vm$dailyReport11 === void 0 ? void 0 : _vm$dailyReport11.drink_sales) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex mt-6"
  }, [_c('div', [_vm._v("その他")]), _c('div', {
    staticClass: "ml-15"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$dailyReport12 = _vm.dailyReport) === null || _vm$dailyReport12 === void 0 ? void 0 : _vm$dailyReport12.others_sales) || 0)) + " ")])])]), _c('div', [_c('div', {
    staticClass: "d-flex mt-n8"
  }, [_c('div', [_vm._v("デリバリー")]), _c('div', {
    staticClass: "ml-8"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$dailyReport13 = _vm.dailyReport) === null || _vm$dailyReport13 === void 0 ? void 0 : _vm$dailyReport13.delivery_sales) || 0)) + " ")])])])]), (_vm$dailyReport14 = _vm.dailyReport) !== null && _vm$dailyReport14 !== void 0 && _vm$dailyReport14.notes ? _c('div', {
    staticClass: "pb-8"
  }, [_c('div', {
    staticClass: "message-card ml-4 mr-8"
  }, [_c('div', {
    staticClass: "font-14px my-4 ml-4 text-light-grey mx-683px"
  }, [_vm._v(" " + _vm._s(_vm.dailyReport.notes) + " ")])])]) : _vm._e()] : [_c('div', {
    staticClass: "daily-report-card mt-5 ml-4 d-flex align-center font-14px"
  }, [_c('div', {
    staticClass: "d-flex gray-text ml-3 total_sales"
  }, [_c('div', {
    staticClass: "gray-text"
  }, [_vm._v("総コスト")]), _c('div', {
    staticClass: "ml-14"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport = _vm.costsReport) === null || _vm$costsReport === void 0 ? void 0 : _vm$costsReport.costs) || 0)) + " ")])])]), _c('div', {
    staticClass: "mt-5 pb-7 ml-4 d-flex align-center font-14px gray-text"
  }, [_c('div', {
    staticClass: "sale-summary"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("人件費(L)")]), _c('div', {
    staticClass: "ml-12"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport2 = _vm.costsReport) === null || _vm$costsReport2 === void 0 ? void 0 : _vm$costsReport2.labor_cost) || 0)) + " (" + _vm._s((_vm$costsReport3 = _vm.costsReport) === null || _vm$costsReport3 === void 0 ? void 0 : _vm$costsReport3.labor_cost_percentage) + "%) ")])]), _c('div', {
    staticClass: "d-flex mt-6"
  }, [_c('div', {
    staticClass: "ml-3"
  }, [_vm._v("PF手数料(P)")]), _c('div', {
    staticClass: "ml-7"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport4 = _vm.costsReport) === null || _vm$costsReport4 === void 0 ? void 0 : _vm$costsReport4.platform_cost) || 0)) + " (" + _vm._s((_vm$costsReport5 = _vm.costsReport) === null || _vm$costsReport5 === void 0 ? void 0 : _vm$costsReport5.platform_cost_percentage) + "%) ")])])]), _c('div', {
    staticClass: "sale-summary"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', [_vm._v("食材費(F)")]), _c('div', {
    staticClass: "ml-12"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport6 = _vm.costsReport) === null || _vm$costsReport6 === void 0 ? void 0 : _vm$costsReport6.foodstuff_cost) || 0)) + " (" + _vm._s((_vm$costsReport7 = _vm.costsReport) === null || _vm$costsReport7 === void 0 ? void 0 : _vm$costsReport7.food_cost_percentage) + "%) ")])]), _c('div', {
    staticClass: "d-flex mt-6"
  }, [_c('div', [_vm._v("その他(O)")]), _c('div', {
    staticClass: "ml-12"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport8 = _vm.costsReport) === null || _vm$costsReport8 === void 0 ? void 0 : _vm$costsReport8.others_cost) || 0)) + " (" + _vm._s((_vm$costsReport9 = _vm.costsReport) === null || _vm$costsReport9 === void 0 ? void 0 : _vm$costsReport9.others_cost_percentage) + "%) ")])])]), _c('div', [_c('div', {
    staticClass: "d-flex mt-n8"
  }, [_c('div', [_vm._v("家賃(R)")]), _c('div', {
    staticClass: "ml-14"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport10 = _vm.costsReport) === null || _vm$costsReport10 === void 0 ? void 0 : _vm$costsReport10.rent_cost) || 0)) + " (" + _vm._s((_vm$costsReport11 = _vm.costsReport) === null || _vm$costsReport11 === void 0 ? void 0 : _vm$costsReport11.rent_cost_percentage) + "%) ")])])])])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }